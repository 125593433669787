import { Tuple, configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import { thunk } from "redux-thunk";

const store = configureStore({
  reducer: rootReducer,
  middleware: () => new Tuple(thunk),
});


export { store };