import { useEffect, useState } from "react";
import Header from "../components/Header";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getQuoteList } from "../redux/publicApi";
import QuoteCard from "../components/QuoteCard";
import { useNavigate, useLocation } from "react-router-dom";
// eslint-disable-next-line
import ColoredCard from "../components/ColoredCard";
import { Language } from "@/types";
import Footer from "../components/Footer";
import usePageSEO from "../../../hooks/usePageSEO";
import QuotePopup from "../components/QuotePopup";

export default function Home() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedLang, setSelectedLang] = useState<Language>({ label: "", value: "", code: "" });
    const [selectedCard, setSelectedCard] = useState<any>(null);
    const [search, setSearch] = useState("")

    useEffect(() => {
        const data = {
            search: search,
            language: selectedLang.label
        }
        dispatch(getQuoteList(data));
    }, [dispatch, selectedLang, search]);

    const { quoteList } = useSelector((state: any) => state.public, shallowEqual);

    const getQueryParams = (query: string) => {
        return new URLSearchParams(query);
    };

    useEffect(() => {
        const queryParams = getQueryParams(location.search);
        const quoteId = queryParams.get("quoteId");

        if (quoteId && quoteList?.length > 0) {
            const card = quoteList.find((quote: any) => quote.id === quoteId);
            if (card) {
                setSelectedCard(card);
            }
        }
    }, [location.search, quoteList]);

    const openPopup = (card: any) => {
        setSelectedCard(card);
        navigate(`?quoteId=${card.id}`);
    };

    const closePopup = () => {
        setSelectedCard(null);
        navigate("/");
    };

    usePageSEO({
        title: "Best Quotes - My words",
        description: "My words",
        keywords: [],
        ogTitle: "",
        ogDescription: "",
        ogImage: "",
        ogUrl: ""
    })

    return (
        <>
            <Header
                selectedLang={selectedLang}
                setSelectedLang={setSelectedLang}
                search={search}
                setSearch={setSearch}
            />
            <main>
                <div className="container">
                    <div className="card-grid">
                        {quoteList?.length > 0 &&
                            quoteList.map((ele: any) => (
                                <div key={ele.id} onClick={() => openPopup(ele)}>
                                    <QuoteCard data={ele} />
                                </div>
                            ))
                        }
                    </div>
                    {/* <div className="card-grid">
                        {quoteList?.length > 0 &&
                            quoteList.map((ele: any) => (
                                <div key={ele.id} onClick={() => openPopup(ele)}>
                                    <ColoredCard data={ele} />
                                </div>
                            ))
                        }
                    </div> */}
                </div>

                {selectedCard && (
                    // <div className="popup-overlay" onClick={closePopup}>
                    //     <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                    //         <button type="button" className="close-btn" onClick={closePopup}>x</button>
                    //         <div className="flex-col-2 gap-2">
                    //             <div className="auth-img">
                    //                 <img src={selectedCard.author.photo.localUrl} alt="" />
                    //             </div>
                    //             <div className="qt-box flex-col justify-between">
                    //                 <div className="qtb-detail">
                    //                     <h2>{selectedCard.quote}</h2>
                    //                     <p className="text-right">- {selectedCard?.author?.name}</p>
                    //                 </div>
                    //                 <div className="share-box flex align-center justify-between">
                    //                     <div className="flex align-center gap-half">
                    //                         <i className="fa-light fa-share-nodes"></i>
                    //                         <p>Share</p>
                    //                     </div>
                    //                     <div className="social-buttons">
                    //                         <button
                    //                             type="button"
                    //                             onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&picture=${selectedCard.author.photo.localUrl}`, '_blank')}
                    //                         >
                    //                             <i className="fa-brands fa-facebook-f"></i>
                    //                         </button>
                    //                         <button
                    //                             type="button"
                    //                             onClick={() => window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(selectedCard.quote + " " + window.location.href)}`, '_blank')}
                    //                         >
                    //                             <i className="fa-brands fa-whatsapp"></i>
                    //                         </button>
                    //                         <button
                    //                             type="button"
                    //                             onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(selectedCard.quote + " " + window.location.href)}`, '_blank')}
                    //                         >
                    //                             <i className="fa-brands fa-x-twitter"></i>
                    //                         </button>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     </div>
                    // </div>
                    <QuotePopup selectedCard={selectedCard} closePopup={closePopup}/>
                )}
            </main>
            <Footer />
        </>
    );
}
