import { createSlice } from "@reduxjs/toolkit";
import { getQuoteList } from "./publicApi";

const publicSlice = createSlice({
    name: "public",
    initialState: {
        loading: false,
        quoteList: [],
        metaData: {}
    },
    reducers: {
        isLoading: (state, action) =>
        (state = {
            ...state,
            loading: action.payload,
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(getQuoteList.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(getQuoteList.fulfilled, (state, action) => {
            state.quoteList = action.payload?.data;
            state.metaData = action.payload?.metadata?.[0];
            state.loading = false;
        });
        builder.addCase(getQuoteList.rejected, (state, action) => {
            state.loading = false;
            state.quoteList = [];
            state.metaData = {}
        });
    }
})

export const { isLoading } = publicSlice.actions;
export default publicSlice.reducer