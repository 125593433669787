const setupAxios = (axios: any, store: any) => {
  axios.interceptors.request.use(
    (config: any) => {
      config.headers["Access-key"] = process.env.REACT_APP_ACCESS_KEY;
      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (err: any) => {
      if (err.response && err.response.status === 401) {
        window.location.href = "/";
      }
      return err.response;
    }
  );
};

export default setupAxios;
