import usePageSEO from '../../../hooks/usePageSEO'

export default function QuotePopup({ selectedCard, closePopup }: any) {
    usePageSEO({
        title: selectedCard?.quote,
        description: `Quote by ${selectedCard?.author?.name}`,
        keywords: [],
        ogTitle: selectedCard?.quote,
        ogDescription: `Quote by ${selectedCard?.author?.name}`,
        ogImage: selectedCard.author.photo.localUrl,
        ogUrl: ""
    })
    return (
        <div className="popup-overlay" onClick={closePopup}>
            <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <button type="button" className="close-btn" onClick={closePopup}>x</button>
                <div className="flex-col-2 gap-2">
                    <div className="auth-img">
                        <img src={selectedCard.author.photo.localUrl} alt="" />
                    </div>
                    <div className="qt-box flex-col justify-between">
                        <div className="qtb-detail">
                            <h2>{selectedCard.quote}</h2>
                            <p className="text-right">- {selectedCard?.author?.name}</p>
                        </div>
                        <div className="share-box flex align-center justify-between">
                            <div className="flex align-center gap-half">
                                <i className="fa-light fa-share-nodes"></i>
                                <p>Share</p>
                            </div>
                            <div className="social-buttons">
                                <button
                                    type="button"
                                    onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}&picture=${selectedCard.author.photo.localUrl}`, '_blank')}
                                >
                                    <i className="fa-brands fa-facebook-f"></i>
                                </button>
                                <button
                                    type="button"
                                    onClick={() => window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(selectedCard.quote + " " + window.location.href)}`, '_blank')}
                                >
                                    <i className="fa-brands fa-whatsapp"></i>
                                </button>
                                <button
                                    type="button"
                                    onClick={() => window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(selectedCard.quote + " " + window.location.href)}`, '_blank')}
                                >
                                    <i className="fa-brands fa-x-twitter"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
