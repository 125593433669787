import MaintenancePage from "../common/MaintenancePage";
import NotFoundPage from "../common/NotFoundPage";
import Home from "../modules/public/pages/Home";
import { Route, Routes } from "react-router-dom";

export default function Routing() {
    const maintenance = false;
    return (
        <>
            {
                maintenance ?
                    <Routes>
                        <Route path="/*" element={<MaintenancePage/>} />
                    </Routes> :
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="*" element={<NotFoundPage />} />
                    </Routes>
            }
        </>
    )
}
