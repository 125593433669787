import { useEffect } from "react";

const usePageSEO = ({
    title,
    description,
    keywords = [],
    ogTitle,
    ogDescription,
    ogImage,
    ogUrl,
    canonicalUrl
}: any) => {

    useEffect(() => {
        document.title = title;
        setMetaTag('name', 'description', description);
        setMetaTag('name', 'keywords', keywords);
        setMetaTag('property', 'og:title', ogTitle || title);
        setMetaTag('property', 'og:description', ogDescription || description);
        setMetaTag('property', 'og:image', ogImage);
        setMetaTag('property', 'og:url', ogUrl || window.location.href);
        setCanonicalUrl(canonicalUrl || window.location.href);
    }, [title, description, keywords, ogTitle, ogDescription, ogImage, ogUrl, canonicalUrl]);

    const setMetaTag = (attr: any, key: any, content: any) => {
        if (content) {
            let element = document.querySelector(`meta[${attr}="${key}"]`);
            if (!element) {
                element = document.createElement('meta');
                element.setAttribute(attr, key);
                document.head.appendChild(element);
            }
            element.setAttribute('content', content);
        }
    };

    const setCanonicalUrl = (url: string) => {
        let linkElement = document.querySelector(`link[rel="canonical"]`);
        if (!linkElement) {
            linkElement = document.createElement('link');
            linkElement.setAttribute('rel', 'canonical');
            document.head.appendChild(linkElement);
        }
        linkElement.setAttribute('href', url);
    };
};

export default usePageSEO;
