import { Languages } from "../../../helper/data";
import { Language } from "@/types";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Header({ setSelectedLang, selectedLang, search, setSearch }: any) {
    const [openLanguageBox, setOpenLanguageBox] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const handleLanguageSelect = (ele: Language) => {
        setSelectedLang(ele);
        setOpenLanguageBox(false)
    };
    return (
        <header className="header">
            <div className="flex align-center justify-between container">
                <div className="logo">
                    <Link to={"/"}>My Words</Link>
                </div>
                <div className="right-bar flex gap-1 align-center">
                    <div className="search-bar">
                        <i className="fa-sharp fa-light fa-magnifying-glass"></i>
                        <input type="text" name="search" id="search" value={search} onChange={(e) => setSearch(e.target.value)} />
                    </div>
                    <div className="mobile-search">
                        <i className="fa-sharp fa-light fa-magnifying-glass" onClick={() => setShowSearch(!showSearch)}></i>
                        <div className={`m-search ${showSearch ? "show" : ""}`}>
                            <i className="fa-sharp fa-light fa-magnifying-glass"></i>
                            <input type="text" name="search" id="search" value={search} onChange={(e) => setSearch(e.target.value)} />
                        </div>
                    </div>
                    <div className="lang-select" onClick={() => setOpenLanguageBox(!openLanguageBox)}>
                        <i className="fa-solid fa-earth-asia"></i>
                        {selectedLang?.code && <span>{selectedLang.code}</span>}
                    </div>
                    {
                        openLanguageBox &&
                        <div className="lang-box">
                            {selectedLang.code !== "" && <div className="lang" onClick={() => handleLanguageSelect({ label: "", value: "", code: "" })} >All</div>}
                            {
                                Languages.map((ele, i) =>
                                    <>
                                        <div key={i} className="lang" onClick={() => handleLanguageSelect(ele)} >{ele.label}</div>
                                    </>
                                )
                            }
                        </div>
                    }
                </div>
            </div>
        </header>
    )
}
