import { Language } from "@/types";

export const Languages: Language[] = [
    { label: "English", value: "english", code: "en" },
    { label: "Chinese", value: "chinese", code: "zh" },
    { label: "Hindi", value: "hindi", code: "hi" },
    { label: "Spanish", value: "spanish", code: "es" },
    { label: "French", value: "french", code: "fr" },
    { label: "Arabic", value: "arabic", code: "ar" },
    { label: "Bengali", value: "bengali", code: "bn" },
    { label: "Russian", value: "russian", code: "ru" },
    { label: "Portuguese", value: "portuguese", code: "pt" },
    { label: "Urdu", value: "urdu", code: "ur" },
];