import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isLoading } from "./publicSlice";

export const getQuoteList: any = createAsyncThunk(
    "public/quote-list",
    async (data: any, { fulfillWithValue, rejectWithValue, dispatch }) => {
        try {
            dispatch(isLoading(true))
            const response = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/public/quote-list/?page=0&limit=0&sort=desc&search=${data?.search}&filter[name]=&filter[country]=&filter[language]=${data?.language}`,
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (response.status === 200) {
                dispatch(isLoading(false))
                return fulfillWithValue(response?.data?.data?.[0]);
            } else {
                dispatch(isLoading(false))
                return rejectWithValue(response.data);
            }
        } catch (error: any) {
            dispatch(isLoading(false))
            return rejectWithValue(error.message);
        }
    }
)