import { Link } from "react-router-dom"

const NotFoundPage = () => {
  return (
    <div className="error-page">
      <img src="/assets/images/thinking.png" alt="" />
      <h2 className="text-center">404 - Page Not Found</h2>
      <p className="text-center">Oops! It seems the page you're looking for has wandered off into a poetic limbo. Perhaps try searching for something else or return to our homepage.</p>
      <Link className="btn" to="/">Go to Homepage</Link>
    </div>
  )
}

export default NotFoundPage