export default function QuoteCard({ data }: any) {
    return (
        <div className="quote-card" style={{
            background: `url(${data.author?.photo?.localUrl})`,
            backgroundColor: "#313131",
            backgroundSize: "cover", 
            backgroundPosition:"top center"
        }}>
            <h3 className="quote" data-quote={data.quote}>{data.quote}</h3>
            {/* <img src={data.author?.photo?.localUrl} alt={data.author?.name} title={data.author?.name} className="author-image" /> */}
            <div className="author-bar">
                <p className="author-name text-right" data-author={data.author?.name}>{data.author?.name}</p>
                <p className="author-desc text-right" data-profession={data.author?.profession}>{data.author?.profession}</p>
            </div>
        </div>
    )
}
